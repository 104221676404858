body {
  font-family: "Space Grotesk", sans-serif;
  background-color: #010513;
}

a {
  color: #DA3BEE;
  text-decoration: none;
}

a:hover {
  color: #8F4F97;
}

.App {
  text-align: center;
  background-image: url("../public/images/Background.png");
  background-repeat: no-repeat;
  background-position: 50% top;
  background-size: cover;
  background-color: #010513;
}

.companyLogoWhite {
  width:100px;
  opacity:50%;
  transition: all 0.3s;
}

.companyLogoWhite:hover {
  opacity:100%;
}

.mainLogo {
  width:75%;
  max-width:950px;
  min-width: 350px;
  pointer-events: none;
}

.mainLogoImage {
  width:100%;
}

.mainHeader {
  font-size: clamp(16px, 2.5vw, 32px);
  width:70%;
  max-width:900px;
  min-width: 200px;
  margin:0 0 60px 0;
  color:#bdaccd;
  text-shadow: rgba(0, 0, 0, 0.5) 0 0px 2px;
}

.mainHeaderSubtitle {
  font-size: max(12px,50%);
  display: table;
  margin: 10px auto 0 auto;
  color: #b69cd2;
  padding:4px 15px;
  background: #240b28;
  border-radius:150px;
  text-shadow: none;
}

.socialMediaBar {
  position:absolute;
  left:20px;
  margin-top:auto;
  margin-bottom:auto;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.socialMediaBar svg {
  width: 30px;
  height: 30px;
  margin-top:10px;
  margin-right: 10px;
  transition: all 0.3s;
  cursor: pointer;
}

.socialMediaBar svg:hover {
  width: 35px;
  height: 35px;
}

.socialMediaBar svg g {
  transition: all 0.3s;
}

.socialMediaBar svg:hover g {
  fill: #DA3BEE;
}

button {
  font-family: "Space Grotesk", sans-serif;
  padding:clamp(10px, 1.5vw, 12px) clamp(20px, 2.5vw, 32px);
  font-size:clamp(14px, 2.5vw, 20px);
  background: rgba(0, 0, 0, 0.5);
  border: clamp(2px, 0.3vw, 6px) solid #8F4F97;
  border-radius:360px;
  color:white;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}

button:first-child {
  margin-right:clamp(20px, 2.5vw, 30px);
}

button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(102%);
  color:#DA3BEE;
  border-color: #DA3BEE;
}

.mainButtons {
  display: flex;
  flex-direction: row;
  margin-bottom:80px;
}

.headerLinks {
  color:#EFE9D8;
  display: flex;
  padding:20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headerLinks .headerLeft {
  color:#bdaccd;
}

.headerLinks .headerRight {
}

.headerLinks a {
  color: #EFE9D8;
  font-weight: 500;
  font-size: clamp(14px, 2.5vw, 18px);
}

.headerLinks a:hover {
  color: #b3ae9f;
}

.socialMediaBarGradient {
  display:none;
}

@media only screen and (max-width: 600px) {
  .socialMediaBarGradient {
    background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    position:fixed;
    left:0;
    bottom:0;
    right:0;
    height:80px;
  }

  .socialMediaBar {
    position:fixed;
    left:auto;
    bottom:20px;
    margin-top:0;
    margin-bottom:0;
    right:auto;
    display: flex;
    flex-direction: row;
  }

  button {
    display: block;
    margin-right:0;
  }

  button:first-child {
    margin-bottom:10px;
    margin-right: 0 !important;
  }

  .mainButtons {
    flex-direction: column;
  }

  .App {
    background-size: auto 50%;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
