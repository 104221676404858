.pressContainer {
    font-family: "Space Grotesk", sans-serif;
    background: #fff;
    width:80%;
    max-width:800px;
    padding:40px;
    text-align:left;
    margin: 50px auto 50px auto;
    border-radius: 10px;
    color: #444;
}

.pressContainer h2 {
    font-family: "Space Grotesk", sans-serif;
    color: #222;
}

.pressContainer h3 {
    font-family: "Space Grotesk", sans-serif;
    margin-bottom:5px;
    color: #222;
}

.pressContainer li {
    margin-bottom:10px;
}

.pressContainer .images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.pressContainer .infoPill {
    background: #9ecaff;
    padding:10px 20px;
    margin-bottom:20px;
    color: #154675;
    border-radius:10px;
}

.pressContainer .infoPill a {
    color: #154675;
}

.pressContainer .infoPill a:hover {
    color: #031421;
}

.pressContainer .images .screenshot:hover {
    opacity:80%;
}

.pressContainer .images .screenshot {
    width:100%;
    opacity: 100%;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
}

.pressContainer .companyLogo {
    width: 200px;
    padding: 10px;
    margin:10px;
}

.pressContainer .companyLogoBackground {
    background:#ddd;
}

.twoColumns {
    display:grid;
    column-gap: 50px;
    grid-template-columns: 1fr 2fr;
}

.videoTitle {
    display: block;
    margin:5px 0 10px 0;
}

.videoContainer {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

hr {
    border: 1px solid rgba(0,0,0,0.1);
    margin:30px 0 30px 0;
}

@media only screen and (max-width: 600px) {
    .twoColumns {
        grid-template-columns: none;
    }

    .pressContainer .images {
        grid-template-columns: none;
    }
}